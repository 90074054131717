import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/vsts/work/1/s/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Navigation } from "../../src/components/Navigation";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "rules"
    }}>{`Rules`}</h1>
    <p>{`For the simple use case, where you're working alone on a project;
adding rules about how you work in DevOps only complicates the development cycle.`}</p>
    <p><strong parentName="p">{`But, when you're going to collaborate with others, having rules is key to effective collaboration! 🚦`}</strong></p>
    <h2 {...{
      "id": "git-branches"
    }}>{`Git branches`}</h2>
    <p>{`Branches are defined, as such, by GitHub:`}</p>
    <blockquote>
      <p parentName="blockquote">{`Use a branch to isolate development work without affecting other branches in the repository.
Each repository has one default branch, and can have multiple other branches.
You can merge a branch into another branch using a pull request.`}</p>
      <p parentName="blockquote">{`-- `}<cite><a href="https://docs.github.com/en/pull-requests/collaborating-with-pull-requests/proposing-changes-to-your-work-with-pull-requests/about-branches">{`GitHub Docs`}</a></cite></p>
    </blockquote>
    <p>{`We usually create a new branch from the `}<em parentName="p">{`main branch`}</em>{` whenever we start on a new feature or try to solve an issue.`}<br parentName="p"></br>{`
`}{`When we're happy with the changes we can merge the changes back into the `}<em parentName="p">{`main branch`}</em>{`.`}</p>
    <p>{`If, on the other hand, we're not happy with the changes; we can create a new branch from the `}<em parentName="p">{`main branch`}</em>{` again and try something else.`}</p>
    <p>{`We can freely switch between these branches, without losing the work we're doing in another (pretty useful when you're working on two features in parallel).`}</p>
    <h2 {...{
      "id": "pull-requests"
    }}>{`Pull requests`}</h2>
    <p>{`Pull requests are defined, as such, by GitHub:`}</p>
    <blockquote>
      <p parentName="blockquote">{`Pull requests let you tell others about changes you've pushed to a branch in a repository on GitHub.
Once a pull request is opened, you can discuss and review the potential changes with collaborators
and add follow-up commits before your changes are merged into the base branch.`}</p>
      <p parentName="blockquote">{`-- `}<cite><a href="https://docs.github.com/en/pull-requests/collaborating-with-pull-requests/proposing-changes-to-your-work-with-pull-requests/about-pull-requests">{`GitHub Docs`}</a></cite></p>
    </blockquote>
    <p>{`Pull requests, often called PR, are used to merge branches into each other (usually a feature branch ➡️ main).`}<br parentName="p"></br>{`
`}{`Merging can be done locally in Visual Studio Code, but doing it in Azure DevOps with the use of `}<em parentName="p">{`Pull Request`}</em>{`
allows others to review your work and comment on it. 💬✅`}</p>
    <p>{`It's just more collaborative, and in some cases you actually don't have permission to modify
the main branch directly (can be set in the branch permissions page on Azure DevOps) `}{`—`}{`
forcing you to use a PR. 🔐`}</p>
    <h2 {...{
      "id": "be-a-gentleman-"
    }}>{`Be a gentleman 🎩`}</h2>
    <p>{`So, please follow the following rules when working on other people's projects:`}</p>
    <ol>
      <li parentName="ol">{`Clone the repository`}</li>
      <li parentName="ol">{`Switch to the main branch`}
        <ul parentName="li">
          <li parentName="ul">{`Consult the project owner if there's no branch called `}<em parentName="li">{`main`}</em>{` (they've called it something else).`}</li>
        </ul>
      </li>
      <li parentName="ol">{`Pull the latest changes`}
        <ul parentName="li">
          <li parentName="ul">{`Make it a habit to click that refresh button in the bottom-left corner in Visual Studio Code,
to make sure you never miss any changes that others have made.`}</li>
        </ul>
      </li>
      <li parentName="ol">{`Create a new feature branch`}
        <ul parentName="li">
          <li parentName="ul">{`Give it a simple name, in all lowercase and underscores separating words, for instance: `}<em parentName="li">{`feature_auto_create_order`}</em>{`.`}</li>
        </ul>
      </li>
      <li parentName="ol">{`Make your changes`}
        <ul parentName="li">
          <li parentName="ul">{`Make frequent commits, preferably with small changes, and write your commit messages in `}<a parentName="li" {...{
              "href": "https://cbea.ms/git-commit/"
            }}>{`this style`}</a>{`. 👈 READ IT!`}</li>
          <li parentName="ul">{`Push your commits as you make them, to not lose your work.`}</li>
        </ul>
      </li>
      <li parentName="ol">{`Create a pull request in Azure DevOps`}
        <ul parentName="li">
          <li parentName="ul">{`Make sure you select the correct target and source branches (source is your feature branch, target is main).`}</li>
          <li parentName="ul">{`Add any reviewers if required.`}</li>
        </ul>
      </li>
      <li parentName="ol">{`Wait for the project owner to review, approve and complete the PR`}
        <ul parentName="li">
          <li parentName="ul">{`They control the merging, you just have to answer their comments and make changes accordingly.`}</li>
          <li parentName="ul">{`Make the changes directly in the feature branch `}{`—`}{` as you push more commits, the PR gets updated automatically.`}</li>
        </ul>
      </li>
    </ol>
    <Navigation previous="/my-first-extension/publish-to-devops" mdxType="Navigation" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      